<template>
     <div class="width-full h-screen fixed top-0 right-0 flex items-center justify-center">
        <c-card class="flex flex-col gap-5 max-w-max py-3 px-5" style="width: 400px">
            <p class="text-jet text-base font-bold border-b border-solid border-romanSilver">
                Action Required!
            </p>
            <div class="flex flex-col justify-start items-start gap-0">
                <p class="text-jet text-base font-semibold">
                    Do you want to delete this {{criteriaOption}} criteria?
                </p>
            </div>
            <div class="w-full flex justify-center gap-4">
                <c-button
                    variant="primary c-button"
                    :disabled="disableDelBtn"
                    @onClick="onDeleteCriteria"
                    :label="disableDelBtn ? 'Deleting...' : 'Delete'"
                />
                <c-button
                    variant="secondary c-button"
                    label="Cancel"
                    :disabled="disableDelBtn"
                    @onClick="closeModal"
                />
            </div>
      </c-card>
    </div>
</template>
<script>
export default {
    name: "DeleteCriteria",
    components: {
        CCard: () => import("@scelloo/cloudenly-ui/src/components/card"),
        CButton: () => import("@scelloo/cloudenly-ui/src/components/button"),
    },
    props: {
        criteriaOption:{
            type: String,
            default: ""
        },
        disableDelBtn: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    methods: {
        closeModal(){
            this.$emit("cancel");
        },
        onDeleteCriteria(){
            this.$emit("delete-criteria", this.criteriaOption)
        }
    }
};
</script>
<style scoped>
.button-style {
    font-weight: 600 !important;
    font-size: 16px !important;
    font-size: 16px !important;
    line-height: 120% !important;
    padding: 10px 50px !important;
}
.c-button {
    width: 100% !important;
    padding: 10px 40px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.width-full{
    width: 38.5rem;
    z-index: 9999 !important;
    background: rgba(0, 0, 0, .5) !important;
}
</style>
